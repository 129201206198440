<template>
  <page-content :show-search="true" :show-stat="true">
    <template #stat>
      <person-info :member-id="member_id" :info="member_info"/>
    </template>
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="true"
        :show-back="true"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";
import PersonInfo from "@/views/bedExitMonitor/deviceView/PersonInfo";

export default {
  components: {
    PersonInfo,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    const start_date = common.getDateFormat(new Date().getTime() - 7 * 86400 * 1000)
    const end_date = common.getDateFormat()

    return {
      title: common.getMenuName('bedexitview'),
      // is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Mon_Menu_View'),
      member_id: this.$router.currentRoute.params.id,
      member_info: {},
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_view.member_status',
          field: 'messageData',
          change: function (value) {
            switch (value) {
              case 'OffBed':
                return common.getI18n('device_view.member_status_offBed')
              case 'OnBed':
                return common.getI18n('device_view.member_status_onBed')
              case 'OnBedWithBlanket':
                return common.getI18n('device_view.member_status_onBedWithBlanket')
              default:
                return 'Missing message'
            }
          }
        },
        {
          label: 'device_view.time',
          field: 'messageTime',
          change: function (value) {
            return common.transDateTime(value)
          }
        },
      ],
      header_actions: [

      ],
      table_actions: [

      ],
      search_fields: [
        {
          field_start: 'startTime',
          field_end: 'endTime',
          label: 'device_view.date',
          type: 'date_range',
          sm: 12,
          md: 6,
          lg: 4,
          // col: true,
          // width: '450px',
          // search_right: true
        },
        {
          field: 'isOnBed',
          label: 'device_view.member_status',
          type: 'select',
          options: [
            {text: this.$t('device_view.member_status_onBed'), value: true},
            {text: this.$t('device_view.member_status_offBed'), value: false},
          ]
        }

      ],
      search_default: {
        // isActive: true
        startTime: start_date,
        endTime: end_date
      }
    }
  },
  mounted() {
    this.member_id = common.decrypt(this.member_id)
    if (this.member_id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/bemonitor/GetMonitorMemberInfo?memberID='+this.member_id).then(res => {
      this.member_info = res.data.memberInfo
    })

    this.getList()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/bemonitor/GetMonitorMemberRecords'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.records
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)
      where['memberID'] = this.member_id

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    searchChangeField: function (field, value) {
      // if (field === 'companyID') {
      //   delete this.$refs.search.list.facilityID
      //   common.getSearchFacilityOptions('facilityID', value, this)
      //   common.setAdminCompanyId(value)
      //   common.setAdminFacilityId(null)
      // } else if (field === 'facilityID') {
      //   common.setAdminFacilityId(value)
      // }
    },
  }
}
</script>
