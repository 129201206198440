<template>
  <b-row>
    <b-col cols="12" class="d-flex align-items-center" style="height: 40px">
      <span class="wh-dt">{{ $t('common.name') }}：</span>
      <span class="wh-dd mr-2">{{ info.chineseName }} <template v-if="info.englishName">({{ info.englishName }})</template></span>
      <b-button variant="primary" @click="goMemberDetail">{{ $t('device_view.member_detail') }}</b-button>
    </b-col>
    <b-col cols="12" class="d-flex align-items-center" style="height: 40px">
      <div><span class="wh-dt">{{ $t('common.floor') }}：</span><span class="wh-dd mr-2">{{ info.floorName }}</span></div>
      <div><span class="wh-dt">{{ $t('common.room') }}：</span><span class="wh-dd mr-2">{{ info.roomName }}</span></div>
      <div><span class="wh-dt">{{ $t('common.bed') }}：</span><span class="wh-dd">{{ info.bedNumber }}</span></div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import common from "@/common";

export default {
  name: 'PersonInfo',
  components: {
    BRow,
    BCol,
    BButton
  },
  props: {
    memberId: { type: [Number, String] },
    info: { type: Object }
  },
  methods: {
    goMemberDetail: function () {
      this.$router.push({
        name: 'member_edit',
        params: { id: common.encrypt(this.memberId) }
      })
    }
  }
}
</script>

<style scoped>
</style>
